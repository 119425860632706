import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Link from '../../atoms/Link';
import { gridSpanCalc } from '../../base/Layout/Grid';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    '& $list': {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    },
    '& $heading': {
      color: theme.palette.text.headline,
      marginBottom: theme.stack.small
    },
    '& $contentWrapper': {
      padding: [[theme.inset.large, theme.grid.xs.inset]],
      maxWidth: theme.breakpoints.values.lg,
      margin: [[0, 'auto']]
    },
    [theme.breakpoints.up('sm')]: {
      '& $contentWrapper': {
        padding: [[theme.inset.large, theme.grid.sm.inset]]
      },
      '& $heading, $list': {
        padding: [[0, theme.inset.small]]
      }
    },
    [theme.breakpoints.up('md')]: {
      '& $contentWrapper': {
        padding: [[theme.inset.large, theme.grid.md.inset]]
      },
      '& $heading, $list': {
        padding: 0,
        marginLeft: gridSpanCalc(theme, 'lg', 1)
      }
    },
    [theme.breakpoints.up('lg')]: {
      '& $contentWrapper': {
        padding: [[theme.inset.large, theme.grid.lg.inset]]
      },
      '& $heading, $list': {
        padding: [[0, theme.inset.medium]]
      }
    }
  },
  heading: {},
  list: {},
  listItem: {},
  contentWrapper: {}
}));

const SiblingNavigation = props => {
  const { heading, headingLevel, links, className } = props;
  const classes = useStyles(props);

  return (
    links && (
      <nav className={clsx(classes.root, className)}>
        <div className={classes.contentWrapper}>
          {heading && (
            <Typography
              className={classes.heading}
              variant="h4"
              component={`h${headingLevel}`}
            >
              {heading}
            </Typography>
          )}
          <ol className={classes.list}>
            {links.map((link, index) => (
              <li key={index} className={classes.listItem}>
                <Link to={link.to} variant="internal">
                  {link.label}
                </Link>
              </li>
            ))}
          </ol>
        </div>
      </nav>
    )
  );
};

SiblingNavigation.propTypes = {
  heading: PropTypes.string,
  headingLevel: PropTypes.number,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  className: PropTypes.string
};

SiblingNavigation.defaultProps = {
  headingLevel: 3
};

export default SiblingNavigation;
