import { graphql } from 'gatsby';
import { frontmatter, siteMetadata } from '../helper/data';
import { stripTags, markdownToHtml } from '../helper/transformation';

export function mapToProps(data = {}, location = { pathname: 'not/defined' }) {
  const fmData = frontmatter(data);
  const smData = siteMetadata(data);
  const { pathname } = location;

  return {
    url: smData.siteUrl + pathname,
    headline: fmData.intro?.heading ?? fmData.title,
    images: [
      smData.siteUrl + fmData.image?.file?.childImageSharp?.gatsbyImageData?.src
    ],
    authorName: smData.author,
    datePublished: fmData.datePublication,
    publisherName: smData.title,
    publisherLogo: smData.siteUrl + smData.logo,
    description: fmData?.intro?.textMarkdown
      ? stripTags(markdownToHtml(fmData.intro.textMarkdown))
      : fmData?.description
  };
}

export const fragmentFrontmatter = graphql`
  fragment ArticleJsonLdFrontMatter on MarkdownRemarkFrontmatter {
    title
    description
    datePublication
    image {
      file {
        childImageSharp {
          gatsbyImageData(width: 1200, placeholder: NONE, layout: FIXED)
        }
      }
    }
    intro {
      heading
      textMarkdown
    }
  }
`;

export const fragmentSiteMeta = graphql`
  fragment ArticleJsonLdSiteMetadata on SiteSiteMetadata {
    siteUrl
    title
    author
    logo
  }
`;
