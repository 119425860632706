import React from 'react';
import PropTypes from 'prop-types';
import TopBar from '../../organisms/TopBar';
import Main from '../../organisms/Main';
import Intro from '../../organisms/Intro';
import Markdown, { MarkdownHtml } from '../../organisms/Markdown';
import SiblingNavigation from '../../organisms/SiblingNavigation';
import Footer from '../../organisms/Footer';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  intro: {},
  main: {
    marginBottom: theme.stack.large
  }
}));

const Article = props => {
  const {
    markdownComponent,
    TopBarProps,
    IntroProps,
    MarkdownProps,
    SiblingNavigationProps,
    FooterProps
  } = props;
  const MarkdownComponent = markdownComponent || Markdown;
  const classes = useStyles(props);

  return (
    <>
      <TopBar {...TopBarProps} />
      <Main className={classes.main}>
        <Intro className={classes.intro} {...IntroProps} />
        <MarkdownComponent {...MarkdownProps} />
      </Main>
      {SiblingNavigationProps && (
        <SiblingNavigation {...SiblingNavigationProps} />
      )}
      <Footer {...FooterProps} />
    </>
  );
};

Article.propTypes = {
  markdownComponent: PropTypes.oneOf([Markdown, MarkdownHtml]),
  TopBarProps: PropTypes.object.isRequired,
  IntroProps: PropTypes.object.isRequired,
  MarkdownProps: PropTypes.object.isRequired,
  SiblingNavigationProps: PropTypes.object,
  FooterProps: PropTypes.object.isRequired
};

export default Article;
