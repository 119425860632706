import { graphql } from 'gatsby';
import { stripTrailingSlash } from '../../../utils/helpers.js';

export function mapToProps(
  data,
  location,
  heading = 'Other Articles',
  headingLevel = 3
) {
  const { pathname } = location;
  const edges = data?.siblingNavigationItems?.edges ?? [];
  const links = edges.reduce((acc, edge) => {
    const slug = edge?.node?.fields?.slug;
    const title = edge?.node?.frontmatter?.title;

    if (slug && stripTrailingSlash(slug) !== stripTrailingSlash(pathname)) {
      acc.push({ label: title, to: slug });
    }
    return acc;
  }, []);
  return {
    heading,
    headingLevel,
    links
  };
}

export const fragment = graphql`
  fragment SiblingNavigation on Query {
    siblingNavigationItems: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: $templateKey } } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
